<template>
    <div>
        <div class="contentWrapper"> 
            <v-list>
                <v-list-item>
                    <v-list-item-content style="background: #eeeeee">
                        <v-list-item-title class="ml-3 font-weight-medium">CARDS ASSIGNED</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

				<v-list-item class="py-5">
					<v-list style="width: 100%;" class="table pa-0">
						<div class="tableRow tableHeader font-weight-medium">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										Card number
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										Actions
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</div>
						<p style="text-align: center;" class="font-italic grey--text pt-5" v-if="cards.length==0">No data</p>
						<div v-for="item in cards" :key="item.number">
							<div class="tableRow">
								<v-list-item >
									<v-list-item-content class="ma-0">
										<v-list-item-title>
											{{item.identifier}}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<!-- <v-list-item-icon class="mx-0 mt-3 mb-0" v-if="permissions.includes('change_card')">
										<v-icon class="primary--text" dense>mdi-pencil</v-icon>
									</v-list-item-icon> -->
									
									<v-list-item-content style="text-align:center;" v-if="permissions.includes('change_card')">
										<v-list-item-title v-if="item.active"
										@click="openDeactivateDialog(item)" 
										class="red--text" 
										style="cursor: pointer">
											<v-icon class="red--text" dense>mdi-history</v-icon>
											Deactivate
										</v-list-item-title>
										<v-list-item-title v-else
										@click="changeCardStatus(item)" 
										class="green--text" 
										style="cursor: pointer">
											<v-icon class="green--text" dense>mdi-history</v-icon>
											Activate
										</v-list-item-title>
									</v-list-item-content>

									<RemoveRecord v-if="permissions.includes('delete_card')" @confirmDelete="confirmDelete($event)" :item="item" :title="'card '+item.identifier"></RemoveRecord>
								</v-list-item>
							</div>
							<v-divider></v-divider>
						</div>
					</v-list>
				</v-list-item>
			</v-list>

			<v-list v-if="permissions.includes('add_card')">
                <v-list-item class="mt-10">
                    <v-list-item-content style="background: #eeeeee">
                        <v-list-item-title class="ml-3 font-weight-medium">ADD CARD</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
				
				<v-list-item class="mt-5">
					<v-select
					v-model="status"
					:items="dropdownStatus"
					item-text="name"
					item-value="value"
					label="Status">
					</v-select>
				</v-list-item>
				
				<v-list-item>
					<v-text-field
					v-model="identifier"
					label="Card identifier">
					</v-text-field>
				</v-list-item>
            
				<p class="error--text">{{errorMessage}}</p>

				<v-row class="px-7 pb-7" style="display:flex; justify-content: flex-end;">
					<ProgramCardDialog @getCardId="getCardId($event)"  :cardReaders="cardReaders" :workerId="mutableWorkerId" :workerFullName="mutableWorkerFullName" ></ProgramCardDialog>

					<v-btn
					color="primary"
					class="px-15"
					@click="validateNewCard()">
						ADD NEW
					</v-btn>
				</v-row>
			</v-list>
        </div>

		<!-- Confirm deactivation assignment dialog -->
		<v-dialog 
        v-model="deactivateDialog.visible"
		width="300"
        persistent>
            <v-card>
                <v-card-title>Card deactivation</v-card-title>	
				<v-divider></v-divider>
                <v-card-text class="black--text pb-2 messageWrapper">
					<p class="font-weight-regular black--text mt-6 messageWrapper">Are you sure you want to deactivate card {{deactivateDialog.item.identifier}}?</p>
					<div class="actionButtonsWrapper">
						<v-btn
						color="grey"
						class="mr-0 mt-0"
						text
						@click="closeDeactivateDialog()"
						>
						CANCEL
						</v-btn>
						<v-btn
						color="error"
						class="mr-0 mt-0"
						text
						@click="confirmDeactivation(deactivateDialog.item)"
						>
						CONFIRM
						</v-btn>
					</div>
				</v-card-text>
            </v-card>
        </v-dialog>

		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
	</div>
</template>

<script>
//import RemoveRecord from './RemoveRecord.vue'
const RemoveRecord = () => import('./RemoveRecord.vue');
//import ProgramCardDialog from './ProgramCardDialog.vue'
const ProgramCardDialog = () => import('./ProgramCardDialog.vue');
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'Cards',

	components: {
		RemoveRecord,
		ProgramCardDialog
	},

	data: function(){
		return {
			identifier: "",
			status: 0,
			errorMessage: "",
			dropdownStatus: [
				{
					name: "Active",
					value: 0,
				},
				{ 
					name: "Inactive",
					value: 1,
				}
			],
			mutableWorkerFullName: this.workerFullName,
			mutableWorkerId: this.workerId,
			cards:[],
			cardReaders: [],
			errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			},
			deactivateDialog: {
				visible: false,
				item: {}
			},
			permissions: null
		}
	},
    props: {
		workerFullName: String,
		workerId: Number
    },
	beforeMount(){
		if(localStorage.getItem('permissions') !== null){
			this.permissions = JSON.parse(localStorage.permissions);
		}
	},
    mounted() {
		this.$emit("set-state", "default");
		if(this.mutableWorkerFullName && this.mutableWorkerId){
			localStorage.cardsWorkerFullName=JSON.stringify(this.mutableWorkerFullName)
			localStorage.cardsWorkerId=JSON.stringify(this.mutableWorkerId)
		}
		else{
			this.mutableWorkerFullName = JSON.parse(localStorage.cardsWorkerFullName)
			this.mutableWorkerId = JSON.parse(localStorage.cardsWorkerId)
		}
		this.getCardsFromApi()
		this.getCardReadersFromApi()
	},
    methods: {
		getCardId(cardId){
			this.identifier=cardId
			this.getCardsFromApi()
		},
		getCardsFromApi(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Loading data...");
			axios({
					method:'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+this.mutableWorkerId+"/cards",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				}).then((response) => {
					this.adaptApiResponseCards(response.data)
					this.$emit("set-state", "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		getCardReadersFromApi(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Loading data...");
			axios({
					method:'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/card-readers/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				}).then((response) => {
					this.adaptApiResponseCardReaders(response.data)
					this.$emit("set-state", "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
				})
		},
		saveCardChanges(card, newStatus){
			this.$emit("set-state", "LOADING_OVERLAYER", "Saving card changes...");
			let data={
				"activation_at": card.activationAt,
				"expiration_at": card.expirationAt
			}
			if(newStatus!==undefined && newStatus!=null){
				data.status=newStatus? 0: 1
			}
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/cards/"+card.cardId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: data
				}).then(() => {
					this.$emit("set-state", "DEFAULT");
					this.getCardsFromApi()
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('action-result', 'error', 'You do not have priviledges to this action');
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						console.log(error.response.data)
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		addCard(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Creating card...");
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/cards/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						"worker_id": this.mutableWorkerId,
						"identifier": this.identifier,
						"status": this.status,
					}
				}).then(() => {
					this.$emit("set-state", "DEFAULT");
					this.reload()
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('action-result', 'error', 'You do not have priviledges to this action');
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						if(error.response.data.card){
							this.errorMessage=error.response.data.card[0].string
						}
						else{
							this.errorDialog.errorTitle = "Request rejected";
							this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
							this.errorDialog.model = true;
						}
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		deleteCard(cardId){
			this.$emit("set-state", "LOADING_OVERLAYER", "Removing card...");
			axios({
					method:'delete',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/cards/"+cardId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
				}).then(() => {
					this.$emit("set-state", "DEFAULT");
					this.getCardsFromApi()
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						this.$emit('action-result', 'error', 'You do not have priviledges to this action');
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		adaptApiResponseCards(items){
			let cards = []
			if(items){
				for(let item of items){
					cards.push({
						cardId: item.id,
						identifier: item.identifier,
						activationAt: item.activation_at,
						expirationAt: item.expiration_at,
						active: item.status? false: true
					})
				}
			}
			this.cards=cards
		},
		adaptApiResponseCardReaders(items){
			let cardReaders = []
			if(items){
				for(let item of items){
					cardReaders.push({
						id: item.id,
						name: item.device_id,
						description: item.description
					})
				}
			}
			this.cardReaders=cardReaders
		},
		changeCardStatus(item){
			// item.active=(!item.active)
			this.saveCardChanges(item, !item.active)
		},
		validateNewCard(){
			if(this.identifier==""){
				this.errorMessage="Identifier field cannot be empty."
				return
			}
			this.addCard()

		},
		openDeactivateDialog(item){
			this.deactivateDialog.visible=true,
			this.deactivateDialog.item=item
		},
		closeDeactivateDialog(){
			this.deactivateDialog.visible=false,
			this.deactivateDialog.item={}
		},
		confirmDeactivation(item){
			this.saveCardChanges(item, !item.active)
			this.closeDeactivateDialog()
		},
		confirmDelete(item){
			item.deleted=true
			this.deleteCard(item.cardId)
		},
		reload(){
			this.identifier=""
			this.status=0
			this.errorMessage=""
			this.getCardsFromApi()
		}
    }
	};
</script>

<style scoped>

.contentWrapper{
    padding-left: 20px;
    width: 600px;
	overflow:visible;
}

.sectionTitleWrapper{
    background: #eeeeee;
    font-weight: bolder;
    padding: 5px;
    display: flex;
    align-content: center;
}

.table{
	border: 1px solid black;
}

.tableRow{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.tableHeader{
	background: #cccccc;
}


</style>
